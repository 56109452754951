import $ from 'jquery';
import validate from 'validate.js';
import { stepOneValidation, allStepValidation } from './validations';
import { centerDonationForm, getFormData, addErrorsToFields, clearErrors } from './form-utils';

// Inform the customer that there was an error e.g. card could not be charged
function addErrorsToForm(message) {
  const errorElement = $('#card-errors');
  errorElement.text(message);
}

// Initialize Stripe / Stripe Elements
const stripe = Stripe(stripePublishableKey);
const elements = stripe.elements({
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:200,400,500,700,800' }],
});

// Styles for Stripe Elements
const style = {
  base: {
    fontSize: '16px',
    color: '#929292',
    fontFamily: 'Montserrat',
    fontWeight: 500,
  },
};

const formErrors = {};

$(function() {
  // Don't execute the donations code unless we're on the donations page
  if (!$('.donations-controller').length) return;

  centerDonationForm();
  const $form = $('#payment-form');
  const $submitButton = $form.find('.submit');
  const $formFields = $form.find('input');

  // Create an instance of the card Element.
  const card = elements.create('card', { style });

  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-element');

  card.addEventListener('change', ({ error }) => {
    const displayError = document.getElementById('card-errors');
    if (error) {
      displayError.textContent = error.message;
      centerDonationForm();
    } else {
      displayError.textContent = '';
      centerDonationForm();
    }
  });

  $('.continue').on('click', function() {
    // Validate the first step: name, amount and currency fields
    const formData = getFormData($form, ['name', 'amount']);
    const formErrors = validate(formData, stepOneValidation, { fullMessages: false });

    // Display form errors and exit
    if (formErrors) {
      addErrorsToFields($formFields, formErrors);
      centerDonationForm();
      return false;
    } else {
      clearErrors($formFields);
    }

    centerDonationForm();

    // All good
    //   - Remove the continue button
    //   - Display the second part of the form
    $(this)
      .parent()
      .remove();
    $('.form-part-two').slideDown(function() {
      centerDonationForm();
    });
  });

  // Create a token or display an error when the form is submitted.
  $('#payment-form').on('submit', async function(event) {
    event.preventDefault();

    // Validate the first step: name, amount and currency fields
    const formData = getFormData($form, ['name', 'amount', 'email']);
    const formErrors = validate(formData, allStepValidation, { fullMessages: false });

    // Display form errors and exit
    if (formErrors) {
      addErrorsToFields($formFields, formErrors);
      centerDonationForm();
      return false;
    } else {
      clearErrors($formFields);
    }

    centerDonationForm();

    // Only disable the submit button if the form is valid and we'll make the network request
    $submitButton.attr('disabled', true).text('Processing...');

    const { token, error } = await stripe.createToken(card);

    if (error) {
      addErrorsToForm(error.message);
      $submitButton.removeAttr('disabled').text('Donate');
      centerDonationForm();
    } else {
      // Send the token to your server.
      $('#card-errors').text('');
      stripeTokenHandler(token);
    }
  });

  const stripeTokenHandler = token => {
    // Insert the token ID into the form so it gets submitted to the server
    const $form = $('#payment-form');
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    $form.append(hiddenInput);
    const serializedFormData = $form.serialize();

    $.post('/donations', serializedFormData)
      .done(function(data) {
        if (data.success) {
          $('.flip-container').addClass('active');
          centerDonationForm();
        } else {
          addErrorsToForm(data.error);
        }
      })
      .fail(function(data) {
        addErrorsToForm('There was an unexpected error. If this persists, please contact support.');
      })
      .always(function() {
        $submitButton.removeAttr('disabled').text('Donate');
        $submitButton.text('Processing...');
      });
  };
});
