import $ from 'jquery';

var START_DELTA = 100;

$(function() {
  const counterElements = $('.metric .count');

  counterElements.each((index, element) => {
    var count = parseInt(
      $(element)
        .text()
        .replace(',', ''),
      10,
    );
    var countStart = count - START_DELTA;
    if (countStart < 0) {
      countStart = 0;
    }
    var i = setInterval(function() {
      $(element).text(countStart.toLocaleString('en-GB'));
      if (countStart >= count) {
        clearInterval(i);
      }
      countStart += 1;
    }, 30);
  });
});
