import $ from 'jquery';

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const navigationWrapWidth = 700;

const hideNavigation = function() {
  $('.main-navigation, .close, .first-row, .header').removeClass('is-visible');
};

const resetNavigation = debounce(function() {
  const width = $(window).width();
  if (width > navigationWrapWidth) {
    hideNavigation();
    $('body, html').removeClass('stop-scrolling');
  }
}, 50);

$(function() {
  // Toggle the navigation if there's a click on the hamburger or close icons
  $('.hamburger, .close').on('click', function() {
    $('.main-navigation, .hamburger, .close, .first-row, .header').toggleClass('is-visible');
    $('body, html').toggleClass('stop-scrolling');
  });

  // Because some of the links are anchors, potentially linking within the current page,
  // we need to manually hide the navigation as there won't always be a page reload.
  $('.main-navigation .navigation-link').on('click', function() {
    // Only for mobile
    if ($(window).width() > navigationWrapWidth) return;

    hideNavigation();
    $('.hamburger').addClass('is-visible');
    $('body, html').removeClass('stop-scrolling');
  });

  $(window).resize(resetNavigation);
});
