import $ from 'jquery';
import ClipboardJS from 'clipboard';

const autoCompleteOptions = {
  serviceUrl: '/schools/search',
  minChars: 2,
  lookupLimit: 10,
  formatResult, // Customize the results to include the city / address
  paramName: 'q',
  showNoSuggestionNotice: true, // TODO: Add a school??
  triggerSelectOnValidInput: false, // Force a single result to be selected before navigation
  noSuggestionNotice: `<a class="autocomplete-add-new-school" href="/schools/new">Can't find your school?</a>`,
};

function formatResult(suggestion, currentValue) {
  const name = $.Autocomplete.defaults.formatResult(suggestion, currentValue);
  const address = [
    suggestion.data.administrativeDivision1,
    suggestion.data.administrativeDivision2,
    suggestion.data.countryCode,
  ].join(', ');
  return `
        <div class="autocomplete-suggestion-name">${name}</div>
        <div class="autocomplete-suggestion-address">${address}</div>
      `;
}

$(function() {
  const $leaderboardSpinner = $('.school-leaderboard-callout .search-spinner');
  const $searchSpinner = $('.school-search-callout .search-spinner');

  // Initialize the copy for the shareable link
  new ClipboardJS('.copy-link');

  $('.copy-link').on('click', function() {
    $(this).text('Copied!');
  });

  // Allow school table rows to be clickable
  $('.top-schools').on('click', '.clickable-row', function() {
    window.location = $(this).data('href');
  });

  // Ajax Pagination
  $('.pagination').on('click', 'a', function(event) {
    event.preventDefault();
    const path = this.pathname + this.search;
    $('.top-schools').css({ opacity: 0.3 });
    $.ajax(path, { dataType: 'script' }).fail(function(data) {
      console.log(data);
    });
  });

  // Always clear out the input on page load because sometimes the browser
  // caches the value causing it to redirect to the school page on focus.
  $('.school-search-input, .school-leaderboard-search-input').val('');

  // Bind the school search autocomplete
  $('.school-search-input').autocomplete({
    ...autoCompleteOptions,
    onSearchStart: function(params) {
      $searchSpinner.show();
    },
    onSearchComplete: function(params) {
      $searchSpinner.hide();
    },
    onSelect: function(suggestion) {
      window.location = `/schools/${suggestion.data.hashId}`;
    },
  });

  // Bind the leaderboard autocomplete
  $('.school-leaderboard-search-input').autocomplete({
    ...autoCompleteOptions,
    onSearchStart: function(params) {
      $leaderboardSpinner.show();
    },
    onSearchComplete: function(params) {
      $leaderboardSpinner.hide();
    },
    onSelect: function(suggestion) {
      // window.location = `/schools/${suggestion.data.hashId}#leaderboard`;
      $('.top-schools').css({ opacity: 0.3 });
      $.ajax(`/schools/${suggestion.data.hashId}`, { dataType: 'script' }).fail(function(data) {
        console.log(data);
      });
    },
  });
});
