import $ from 'jquery';

export function getFormData($form, keys = []) {
  const formData = {};
  $form.serializeArray().map(({ name, value }) => {
    if (keys.includes(name)) {
      if (name === 'amount') {
        formData[name] = isNaN(parseInt(value, 10)) ? value : parseInt(value, 10);
      } else {
        formData[name] = value;
      }
    } else {
      formData[name] = value;
    }
  });
  return formData;
}

export function clearErrors($formFields) {
  $formFields.each(function(index) {
    $(this)
      .parent()
      .removeClass('has-error')
      .find('.error')
      .remove();
  });
}

export function addErrorsToFields($formFields, formErrors) {
  // Always clear existing errors before attempting a validation
  clearErrors($formFields);

  $formFields.each(function(index) {
    const fieldName = $(this).attr('name');
    if (Object.keys(formErrors).includes(fieldName)) {
      const errorMarkup = `<p class="error">${formErrors[fieldName][0]}</p>`;
      $(this)
        .parent()
        .addClass('has-error')
        .append(errorMarkup);
    }
  });
}

// After a the container's height changes we manually call this to re-center it vertically.
// The container's
export function centerDonationForm() {
  const containerHeight = window.innerWidth < 850 ? 650 : 800;

  if ($('.flip-container').hasClass('active')) {
    const $donateFormBack = $('#donate-form-back');
    const donateFormBackHeight = $donateFormBack.height();
    $('#donate-form-back').css('marginTop', (containerHeight - donateFormBackHeight) / 2);
  } else {
    const $donateForm = $('#donate-form');
    const donateFormHeight = $donateForm.height();
    $('#donate-form').css('marginTop', (containerHeight - donateFormHeight) / 2);
  }
}
