/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Styles
require('../stylesheets/application.scss');

// Images
require('../images/avatars.jpg');
require('../images/bg-store-hero.jpg');
require('../images/default-bg-primary.jpg');
require('../images/default-bg-secondary.jpg');
require('../images/icons/checkmark.svg');
require('../images/icons/down-arrow.svg');
require('../images/icons/down-chevron.svg');
require('../images/icons/down-triangle.svg');
require('../images/icons/download.svg');
require('../images/icons/facebook.svg');
require('../images/icons/instagram.svg');
require('../images/icons/menu.svg');
require('../images/icons/twitter.svg');
require('../images/icons/white/facebook.svg');
require('../images/icons/white/instagram.svg');
require('../images/icons/white/twitter.svg');
require('../images/icons/white/url.svg');
require('../images/icons/x.svg');
require('../images/logos/no2plastic-with-fade.svg');
require('../images/metrics/plastics-all.svg');
require('../images/metrics/plastics-bags-white.svg');
require('../images/metrics/plastics-bags.svg');
require('../images/metrics/plastics-bottles-white.svg');
require('../images/metrics/plastics-bottles.svg');
require('../images/metrics/plastics-cutlery-white.svg');
require('../images/metrics/plastics-cutlery.svg');
require('../images/metrics/plastics-straws-white.svg');
require('../images/metrics/plastics-straws.svg');
require('../images/no-bags-white.svg');
require('../images/no-bags.svg');
require('../images/no-bottles-white.svg');
require('../images/no-bottles.svg');
require('../images/no-straws-white.svg');
require('../images/no-straws.svg');
require('../images/schools/intro-bottle.png');
require('../images/schools/pdf-preview.png');

// Javascripts
require('../javascripts/jquery-init.js');
require('../javascripts/vendor/jquery.autocomplete.js');
require('../javascripts/submit-pledge-form.js');
require('../javascripts/manage-signup-callout-height.js');
require('../javascripts/animate-metrics.js');
require('../javascripts/payments.js');
require('../javascripts/navigation.js');
require('../javascripts/resources');
require('../javascripts/schools.js');
