import $ from 'jquery';

$(function() {
  const $switcher = $('.switcher');

  $switcher.on('click', '> a', function(event) {
    event.preventDefault();
    const $element = $(this);
    const $categoryPanel = $(`.category-${$element.parent().data('category')}`);
    const subcategoryPanelClass = `.subcategory-${$element.attr('href').substring(1)}`;

    // Switch the active nav
    $switcher.find('a').removeClass('active');
    $element.addClass('active');

    // Switch the active panel
    $categoryPanel.find('.subcategory').removeClass('active');
    $categoryPanel.find(subcategoryPanelClass).addClass('active');
  });
});
