// https://stackoverflow.com/questions/8425701/ajax-mailchimp-signup-form-integration

import $ from 'jquery';
import { setHeight } from './manage-signup-callout-height';
import { getFormData } from './form-utils';

function displayThanks() {
  $('.flip-container').addClass('active');
  setHeight(true);
}

function enableHonestyMessage() {
  $('#honesty-message').show();
}

function showMailchimpErrorMessage(msg) {
  var errorMessage;
  var elem;
  var index = msg.charAt(0);

  // This is set up as per the mailchimp field IDs
  var fields = {
    0: $('.email-field'),
    1: $('.name-field'),
    2: $('.website-field'),
  };

  // Error messages relating to a field have a message that looks like:
  //
  //  0 - Please enter a value
  //
  // Error messages relating to the entire submission look like this:
  //
  //   timfletcher1979@gmail.com is already subscribed to list Individual Pledge

  if (isNaN(index)) {
    index = null;
    errorMessage = msg;
  } else {
    errorMessage = msg.replace(index + ' - ', '');
  }

  elem = fields[index || 0];
  elem.addClass('error');
  $('<p class="error-messages">' + errorMessage + '</p>').insertAfter(elem);
}

function showRailsErrorMessage(errors) {
  if (errors.website) {
    const elem = $('.website-field');
    elem.addClass('error');
    $('<p class="error-messages">' + errors.website[0] + '</p>').insertAfter(elem);
  }
  if (errors.company_name) {
    const elem = $('.company-name-field');
    elem.addClass('error');
    $('<p class="error-messages">' + errors.company_name[0] + '</p>').insertAfter(elem);
  }
  if (errors.email) {
    const elem = $('.email-field');
    elem.addClass('error');
    $('<p class="error-messages">' + errors.email[0] + '</p>').insertAfter(elem);
  }
  if (errors.name) {
    const elem = $('.name-field');
    elem.addClass('error');
    $('<p class="error-messages">' + errors.name[0] + '</p>').insertAfter(elem);
  }
}

function requireAtLeastOneCheckbox(form) {
  var checkboxes = form.find('input[type=checkbox]:checked');
  if (checkboxes.length === 0) {
    $('.checkboxes').append('<p class="error-messages">Please select at least one checkbox</p>');
    return false;
  }
  return true;
}

function initAgeVerificationShowHideFields($form) {
  const $fields = $form.find('.name-field, #email-input');
  const $onePledgeNotice = $form.find('.one-pledge-notice');
  $('#over-13').on('click', function() {
    $fields.show();
    $form.find('.error-messages, .thanks').show();
    $onePledgeNotice.hide();
  });
  $('#under-13').on('click', function() {
    $fields.hide();
    $form.find('.error-messages, .thanks').hide();
    $onePledgeNotice.show();
  });
}

function submitPledgeFormToMailchimp($form, successCallback) {
  console.log('Sending to Mailchimp');
  $.ajax({
    type: 'GET', // GET & url for json slightly different
    url: process.env.MAILCHIMP_PLEDGE_ACTION + '-json?c=?',
    data: $form.serialize(),
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    error: function(err) {
      alert('Sorry, your submission could not be processed. Please try again shortly.');
    },
    success: function(data) {
      if (data.result == 'error') {
        showMailchimpErrorMessage(data.msg);
      } else if (data.result != 'success') {
        // Something went wrong, parse data.msg string and display message
      } else {
        // {
        //   result: "success",
        //   msg: "Almost finished... We need to confirm your email address. To complete the subscription process, please click the link in the email we just sent you."
        //}

        displayThanks();

        // Ensure there's a gap displayed under the thanks modal
        setHeight(true);

        // Hit the server to update our count of successful signups to the mailing list
        // ... or, would it be better to do this via a mailchimp API script? Maybe we just hit it
        // once per day to update the numbers?

        // TODO: Clear out form?
      }
    },
  });
}

function submitPledgeFormToServer($form, submitPledgeFormToMailchimpCallback) {
  $.ajax({
    type: 'POST',
    url: '/pledges',
    data: getFormData($form),
    error: function(err) {
      if (err.status === 422) {
        const errors = err.responseJSON.errors;
        showRailsErrorMessage(errors);
      }
    },
    success: function(data) {
      if (!data.success) {
        alert('Something went wrong, please try again');
      }

      // Once we've a successful pledge submission, we need to decide what to do based on the
      // type of pledge.
      //
      //  - An under-13 school pledge should just display the 'flipped' thanks dialogue
      //  - Any other pledges should continue to submit the pledge data (email) to Mailchimp
      //
      if (data.pledge.kind === 'school' && !data.pledge.is_over_thirteen) {
        // Check the data to see if there's been too many submissions from this IP:
        // If there has we'll display an additional message regarding 'honest' signups.
        if (data.pledge_count_from_ip >= 3) enableHonestyMessage();

        displayThanks();
      } else {
        // Otherwise we should send the signup to mailchimp
        submitPledgeFormToMailchimpCallback($form);
      }
    },
    complete: function() {
      $form.find('.pledge-submit-button').attr('disabled', false);
    },
  });
}

$(function() {
  const pledgeForm = $('.pledge-form');

  initAgeVerificationShowHideFields(pledgeForm);

  pledgeForm.submit(function(e) {
    var $this = $(this);

    $this.find('.pledge-submit-button').attr('disabled', true);

    // Reset errors
    $('.email-field, .name-field, .website-field').removeClass('error');
    $('.error-messages').remove();

    var hasCheckbox = requireAtLeastOneCheckbox($this);
    if (!hasCheckbox) {
      e.preventDefault();
      $this.find('.pledge-submit-button').attr('disabled', false);
      return;
    }

    e.preventDefault();

    // Test sending only to our servers
    submitPledgeFormToServer($this, submitPledgeFormToMailchimp);
  });
});
