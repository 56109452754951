import $ from "jquery";
import { centerDonationForm } from "./form-utils";

var resizeTimer;

export function setHeight(thanks = false) {
  var additionalHeight = 100;
  var pledge = $(".the-pledge");
  var isAmbassadorPledge = !!$(".is_ambassador_pledge").length;
  var pledgeThanks = $(".the-pledge-thanks");
  var elem = $(".flip-container").hasClass("active") ? pledgeThanks : pledge;
  if (isAmbassadorPledge) additionalHeight = 250;

  $(".signup-callout").css({ height: elem.height() + additionalHeight });

  centerDonationForm();
}

$(window).on("resize", function(e) {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(setHeight, 50);
});

// When running this on page load, wait until all the assets are done loading. If we don't do
// this the height will not be correct.
$(window).on("load", function() {
  setHeight();
});
