export const stepOneValidation = {
  name: {
    presence: {
      message: 'Required',
      allowEmpty: false,
    },
  },
  amount: {
    presence: {
      message: 'Required',
      allowEmpty: false,
    },
    numericality: {
      message: 'Not a number',
    },
  },
};

export const allStepValidation = {
  ...stepOneValidation,
  email: {
    email: {
      message: 'Please enter a valid email address',
    },
  },
};
